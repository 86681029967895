<template>
  <v-container fluid>
    <ProgressCircular :isLoaded="isLoaded" />
    <v-card v-if="isLoaded">
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>
          Ви {{ isEditable ? ' редагуєте' : ' додаєте' }} статтю
          <span v-if="categoryId">{{ isEditable ? ' з' : ' до' }} категорії "{{ categoryName }}"</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">{{ formTitle }}</div>
      </v-toolbar>

      <v-card flat color="blue-grey lighten-5">
        <Article @deleteFile="deleteFile" @saveData="saveData" :isEditable="isEditable"></Article>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'InfoConstructArticle',

  components: {
    BackButton,
    ProgressCircular: () => import('@/components/common/ProgressCircular'),
    Article: () => import('@/components/support/info_constructor/categories_articles/ArticleConstructor'),
  },

  data() {
    return {
      ROUTER_LINKS: ROUTER_LINKS,
      currentTab: null,
      isLoaded: false,
    }
  },

  computed: {
    ...mapState('infoConstructor', ['additionalInfoCategory', 'additionalInfoArticle', 'additionalInfoCategory']),
    ...mapGetters('infoConstructor', ['getCreatedAdditionalInfoArticleId']),

    currentCompanyId() {
      return this.$route.params.id
    },
    categoryId() {
      return this.$route.query.categoryId || this.additionalInfoArticle.category?.id
    },
    categoryName() {
      return this.additionalInfoCategory?.name?.uk || ''
    },
    articleId() {
      return this.$route.query.articleId
    },
    isEditable() {
      return this.$route.hash === '#edit'
    },
    formTitle() {
      return this.isEditable ? 'Редагувати статтю' : 'Створити статтю'
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {},
  methods: {
    ...mapActions('infoConstructor', [
      'loadSelectedAdditionalInfoCategory',
      'createNewAdditionalInfoArticle',
      'updateSelectedAdditionalInfoArticle',
      'loadSelectedAdditionalInfoArticle',
      'loadSelectedAdditionalInfoEventType',
      'uploadAdditionalInfoArticleImage',
      'uploadAdditionalInfoArticleVideo',
      'deleteAdditionalInfoArticleFile',
      'loadAdditionalInfoArticlesWithIdentifier',
    ]),
    ...mapActions('logs', ['displayWarningAlert', 'displayErrorAlert']),

    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        if (this.articleId) await this.loadSelectedAdditionalInfoArticle(this.articleId)
        if (this.categoryId) await this.loadSelectedAdditionalInfoCategory(this.categoryId)
        if (this.additionalInfoArticle.event_code)
          await this.loadSelectedAdditionalInfoEventType(this.additionalInfoArticle.event_code.event_type.id)
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    async saveData(article, image, video, isTooltipConstructor = false) {
      // if (!this.isEditable) article.category.id = +this.categoryId
      this.isLoaded = false
      let error = false
      if (article.sort_number) {
        article.sort_number = +article.sort_number
      } else {
        article.sort_number = null
      }
      if (!this.isEditable) {
        await this.createNewAdditionalInfoArticle(article)
      } else {
        const payload = {
          id: article.id,
          updatedArticle: article,
        }
        delete payload.updatedArticle.id
        delete payload.updatedArticle.images
        delete payload.updatedArticle.videos
        try {
          await this.updateSelectedAdditionalInfoArticle(payload)
        } catch (e) {
          error = true
          this.displayErrorAlert({ data: { message: 'Не вдалося зберегти статтю' } })
        }
      }
      if (image) {
        const formData = new FormData()
        formData.append('file', image)
        const payload = {
          articleId: this.getCreatedAdditionalInfoArticleId || this.articleId,
          image: formData,
        }
        await this.uploadAdditionalInfoArticleImage(payload)
      }
      if (video) {
        const formData = new FormData()
        formData.append('file', video)
        const payload = {
          articleId: this.getCreatedAdditionalInfoArticleId || this.articleId,
          video: formData,
        }
        await this.uploadAdditionalInfoArticleVideo(payload)
      }
      this.isLoaded = true
      if (this.isEditable) {
        await this.loadSelectedAdditionalInfoArticle(this.articleId)
      }
      if (!error) {
        const route = article.category?.id
          ? {
              name: 'InfoConstructorArticlesList',
              params: { id: this.currentCompanyId },
              query: { categoryId: article.category.id },
            }
          : { name: 'InfoConstructorArticlesList', params: { id: this.currentCompanyId } }
        await this.$router.push(route)
        if (isTooltipConstructor) await this.loadAdditionalInfoArticlesWithIdentifier()
      }
    },

    async deleteFile(link) {
      const payload = {
        fileLink: link,
        articleId: this.articleId,
      }
      await this.deleteAdditionalInfoArticleFile(payload)
    },
  },
}
</script>

<style scoped></style>
